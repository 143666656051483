import React from "react";
//https://www.sanity.io/guides/import-svg-files-in-react
export const LinkedInIcon = () => {
    return (
        <span className="fa-svg-icon fa-1-75x svg-baseline">
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                width="64.000000pt" height="64.000000pt" viewBox="0 0 64.000000 64.000000"
                preserveAspectRatio="xMidYMid meet">
                <title>linkedin</title>
                <g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
                    fill="#0077b5" stroke="none">
                    <path d="M30 570 c-19 -19 -20 -33 -20 -270 0 -237 1 -251 20 -270 19 -19 33
-20 290 -20 257 0 271 1 290 20 19 19 20 33 20 270 0 237 -1 251 -20 270 -19
19 -33 20 -290 20 -257 0 -271 -1 -290 -20z m145 -64 c0 -52 -63 -62 -73 -12
-7 33 6 47 40 44 29 -3 33 -7 33 -32z m5 -226 l0 -140 -40 0 -40 0 0 140 0
140 40 0 40 0 0 -140z m304 121 c47 -31 55 -57 56 -163 l0 -98 -40 0 -39 0 -3
97 c-3 96 -3 98 -30 107 -18 6 -38 6 -55 0 -28 -9 -28 -11 -31 -107 l-3 -97
-39 0 -40 0 0 140 0 140 98 0 c79 0 102 -4 126 -19z"/>
                </g>
            </svg>
        </span>
    )
}