export const NavLinks = [
  {
    name: "Home",
    url: "/",
  },
  {
    name: "About Us",
    url: "/about",
  },
  {
    name: "Contact Us",
    url: "/contact",
  },
  {
    name: "Blog",
    url: "/blog",
  }
];

//https://www.dreamstime.com/multiethnic-diverse-group-business-coworkers-team-meeting-discussion-top-view-modern-office-copy-space-partnership-image139754388
//https://www.dreamstime.com/dedicated-young-woman-editing-document-modern-office-space-women-laptop-sharing-desk-other-hard-working-co-image113236473

export const SliderTwoData = 
  {
    subTitle: "WELCOME TO MADAY TALENT PARTNERS",
    title: "TALENT\nACQUISITION\nCONSULTING",
    button: {
      label: "LEARN MORE",
      url: "/about",
    },
  };

export const ContactFormTitle = {
  subTitle: "Write us a message",
  title: "Contact Us Today",
  description: "We work differently. No commissions. No placement fees. No retainer. No mark-up fees.",
};

export const BlogHomeSection = {
  subTitle: "our news & articles",
  title: "latest blog posts",
  text: "We work differently. No commissions. No placement fees. No retainer. No mark-up fees.",
};

export const ServiceHomeTwoData = {
  subTitle: "",
  title: "Welcome To Maday Talent Partners",
  text:
    "Maday Talent Partners focuses on long-term human resources planning and finding appropriate candidates that require a very specific skillset.\nNiche markets, technology skills, highly specific experience, and leadership roles call for a thoughtful, long-term approach to talent acquisition.\n\nWe work differently. No commissions. No placement fees. No retainer. No mark-up fees.\n\nBased on each individual client's needs and budget, we bill hourly for our services. This saves organizations a significant amount of money, especially for large-scale ramp-up hiring projects or higher compensated candidates. We'll be there for as long as you need us, whether it's just a few weeks or longer term. We'll support you for as little or as many hours as your needs dictate.\n\nWe work with our clients to source and hire talent regardless of race, color, religion, age, national origin, gender, gender identity, sexual orientation, neurodiversity, or disability.  We believe in representing candidates who bring a diverse mix of minds, backgrounds and experiences and are committed to cultivating an inclusive experience for all job seekers.",
};

export const AboutData = {
  aboutUsContent:{
    title:"How We Work",
    text:"Maday Talent Partners focuses on long-term human resources planning and finding appropriate candidates that require a very specific skillset.\nNiche markets, technology skills, highly specific experience, and leadership roles call for a thoughtful, long-term approach to talent acquisition.\n\nWe work differently. No commissions. No placement fees. No retainer. No mark-up fees.\n\nBased on each individual client's needs and budget, we bill hourly for our services. This saves organizations a significant amount of money, especially for large-scale ramp-up hiring projects or higher compensated candidates. We'll be there for as long as you need us, whether it's just a few weeks or longer term. We'll support you for as little or as many hours as your needs dictate.",
  },
  sectionContent: {
    title: "No commission.\n No placement fee.\n No retainer.\n No mark-up fee.",
    subTitle: "we work differently",
    text:"We are committed to providing our clients with exceptional service.",
  },
  gallery: [],
};

export const TestimonialsOneData = {
  sectionContent: {
    subTitle: "",
    title: "testimonials",
  },
  posts: [
    {
      name: "Anthony R.",
      content:
        "\"Brenda is without doubt the most knowledgeable and professional Talent Sourcer I've met in the past 10 years of my career. Her technical understanding combined with her market experience makes her the best choice when it comes to sourcing hard-to-find technical talent.\"",
    },
    {
      name: "Kristina T.",
      content:
        "\"Brenda is able to actively and successfully source, recruit, negotiate offers, manage vendor relationships, etc. Anyone working with her will find the total package of a leader/contributor who inherently embraces the “big” picture for an organization and also possesses the tactical knowledge to implement the vision.\"",
    },
    {
      name: "Kim J.",
      content:
        "\"Brenda works as a partner to leadership in understanding the business needs and driving HR activities that support them. She builds trusting relationships by consistent delivery and diligent follow-up.\"",
    },
    {
      name: "Jeanne P.",
      content:
        "\"Brenda is an experienced, well-rounded HR professional with a deep knowledge of recruiting. She is supportive and conscientious.\"",
    },
    {
      name: "Darchelle N.",
      content:
        "\"Brenda has demonstrated a commitment to solutions focused partnership and a dedication to partnering to assure an ideal qualification and culture fit for placements. Her responsiveness, dedication, and expertise set her apart from others within her industry.\"",
    },
    {
      name: "Laura H.",
      content:
        "\"Brenda is a pleasure to work with - forthcoming, detailed, responsive, and supportive. She is a consummate HR professional and a great working partner.\"",
    },
  ],
};

export const OurValuesData = {
  sectionContent: {
    title: "our values",
    subTitle: "",
    text: "",
  },
  values: [
    {
      title:"Honesty",
      text:"Doing what we say we are going to do.  Being candid and sincere. Always operating with high ethical standards."
    },
    {
      title:"Determination",
      text:"Persevering towards a difficult goal in spite of obstacles. Intent and focused on results."
    },
    {
      title:"Communication",
      text:"Providing relevant and timely information to both clients and candidates.  Responsiveness.  No \"ghosting.\""
    },
    {
      title:"Agility",
      text:"Adapting quickly and effectively. Creating value to continuously exceed the client's needs, while promoting and responding to change in its environment."
    }
  ]
}

export const MeetTheFounderData = {
  sectionContent: {
    title: "Meet our Founder",
    subTitle: "",
    text: "",
  }
}

export const FeatureTwoData = {
  sectionContent: {
    title: "Our Solutions",
    subTitle: "",
    text: "We offer a variety of solutions based on your unique talent needs:",
  },
  posts: [
    {
      title: "Front-end sourcing",
      text: "",
    },
    {
      title: "Building talent pipelines",
      text: "",
    },
    {
      title: "Interview pre-screening",
      text: "",
    },
    {
      title: "Full cycle recruiting",
      text: "",
    },
    {
      title: "Management training on talent acquisition best practices",
      text: "",
    },
    {
      title: "Developing a talent acquisition process from scratch for start-ups or small businesses",
      text: "",
    },
  ],
};

