import React, { Fragment, useContext, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link as ScrollLink } from "react-scroll";

import { MenuContext } from "@/context/menu-context";
import PopupMenu from "@/components/popup-menu";
import { DoubleUpIcon } from "@/components/svg-double-up";


const Layout = ({ PageTitle, children }) => {
  const { menuStatus } = useContext(MenuContext);
  const [scrollTop, setScrollTop] = useState(false);
  const pageTitle = PageTitle ?? 'Maday Talent Partners';
  const handleScrollTop = () => {
    if (window.scrollY > 70) {
      setScrollTop(true);
    } else if (window.scrollY < 70) {
      setScrollTop(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScrollTop);
    return () => {
      window.removeEventListener("scroll", handleScrollTop);
    };
  }, [scrollTop]);
  return (
    <Fragment>
      <Helmet>
        <title> 
          {pageTitle}
        </title>
      </Helmet>
      <div id="wrapper">{children}</div>
      {true === menuStatus ? <PopupMenu /> : null}

      {scrollTop === true ? (
        <ScrollLink
          to="wrapper"
          smooth={true}
          duration={500}
          id="backToTop"
          className="scroll-to-top showit"
        >
          <DoubleUpIcon />
        </ScrollLink>
      ) : null}
    </Fragment>
  );
};

export default Layout;
